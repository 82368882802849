.App {
  text-align: center;
  scroll-padding: unset;
}
body {
  padding: 0;
  margin: 0;
}

@font-face {
  font-family: 'Montserrat', sans-serif;
  src: url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@1,300&display=swap')
    format('woff');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

/* Remove browser defaults */
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.menu-content img {
  max-width: 220px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}
.api-info h1 {
  padding-top: 20px;
}


.api-content > div {
  padding: 20px 0;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.linksBox {
  width: 100%;
  height: auto;
  min-height: 400px;
  margin: 1.5rem auto 1.5rem;
  color: #6639B7;
  border-radius: 2rem;
}

.react-tabs {
  -webkit-tap-highlight-color: transparent;
}

.links {
  width: 60%;
  margin: 0 12px 0;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  border: 1px solid #6639B7;
  border-bottom: 0px;
  border-radius: 2rem 2rem 0 0;
  padding-left: 0px;
}
.link:not(:first-child) {
  border-left: 1px solid #6639B7;
}

.link {
  width: 50%;
  padding: 1rem;
  list-style: none;
  text-align: center;
  align-content: center;
  cursor: pointer;
  transition: all 0.7s;
  font-size: 1.5714em;
  text-decoration: none;
  color: #6639B7;
}

.link.active {
  background: #6639B7;
  color: white;
}

.link.react-tabs__tab--disabled {
  color: Gray;
  cursor: default;
}

.link:hover {
  background: #8E6FE4;
  color: white;
}

.link:first-child {
  border-top-left-radius: 2rem;
}

.link:last-child {
  border-top-right-radius: 2rem;
}
.tabs {
  border-top: 1px solid #6639B7;
}

.tabs.react-tabs__tab-panel {
  display: none;
}

.tabs.react-tabs__tab-panel--selected {
  display: block;
  font-size: 2rem;
  text-align: center;
  border-top: 1px solid #6639B7;
}

@media only screen and (max-width: 980px) {
  .link {
    padding: 0.5rem;
    font-size: 1rem;
    flex: 1 0 21%;
    word-wrap: break-word;
  }

  .links {
    border: none;
    width: 90%;
  }

  .link.active{
    padding:0;
  }

  .link:not(:first-child) {
    border-left: none;
  }
}

@media only screen and (max-width: 320px) {
  .link {
    padding: 0.5rem;
    font-size: 0.9rem;
    width: 100%;
    border-radius: 0;
    border: none;
    border-top: 1px solid #6639B7;
    border-bottom: 1px solid #6639B7;
  }

  .links {
    margin: 0;
    flex-direction: column;
    width: 100%;
    border-radius: 0;
    border: none;
  }

  .link:first-child {
    border-radius: 0;
    border: none;
  }

  .link:not(:first-child) {
    border-radius: 0;
    border: none;
  }

  .link:not(:last-child) {
    border-bottom: 1px solid #6639B7;
  }
}